.infoblocks {
    margin-bottom: 110px;
    &__item {
        width: 50%;
    }
}

@media (max-width: 992px) {
    .infoblocks {
        margin-bottom: 60px;
    }
}

@media (max-width: 575px) {
    .infoblocks {
        margin-bottom: 30px;
        &__item {
            width: 100%;
            &:not(:last-child) {
                margin-bottom: 36px;
            }
        }
    }
}