.advantage {
    &__icon {
        width: 23%;
        padding-right: 9px;
    }
    &__info {
        width: 77%;
    }
    &__title {
        margin-bottom: 22px;
        font-size: 24px;
        font-weight: 900;
        line-height: 28px;
        text-transform: uppercase;
        color: rgb(var(--fireEngineRed));
    }
    &__dscr {
        font-size: 14px;
        line-height: 18px;
    }
}

@media (max-width: 992px) {
    .advantage {
        &__icon {
            width: 130px;
            max-height: 92px;
            img {
                max-height: inherit;
            }
        }
        &__info {
            width: calc(100% - 130px);
        }
    }
}

@media (max-width: 575px) {
    .advantage {
        &__icon {
            width: 80px;
        }
        &__info {
            width: calc(100% - 80px);
        }
        &__title {
            margin-bottom: 10px;
            font-size: 20px;
            line-height: 24px;
        }
    }
}

@media (max-width: 420px) {
    .advantage {
        flex-direction: column;
        &__icon {
            width: 50px;
            max-height: 42px;
            margin-bottom: 12px;
        }
        &__info {
            width: 100%;
        }
    }
}