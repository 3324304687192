.type {
    position: relative;
    width: 100%;
    max-width: 291px;
    height: 306px;
    margin: 0 auto;
    overflow: hidden;
    &__img {
        width: 100%;
        height: 100%;
        background-position: center;
        background-size: cover;
    }
    &__info {
        position: absolute;
        bottom: 22px;
        left: 0;
        width: 100%;
    }
    &__decor {
        position: absolute;
        bottom: 0;
        left: 0;
        img {
            min-width: 100%;
            max-width: none;
            margin-left: 50%;
            transform: translateX(-50%);
        }
    }
    &__title,
    &__subtitle {
        text-transform: uppercase;
        text-align: center;
        color: #fff;
    }
    &__title {
        font-size: 30px;
        font-weight: 900;
        line-height: 34px;
    }
    &__subtitle {
        font-size: 18px;
        font-weight: 300;
    }
}

@media (max-width: 1120px) {
    .type {
        &__title {
            font-size: 26px;
        }
        &__subtitle {
            font-size: 16px;
        }
    }
}

@media (max-width: 575px) {
    .type {
        &__title {
            font-size: 22px;
        }
        &__subtitle {
            font-size: 14px;
        }
    }
}