.about {
    margin-bottom: 76px;
    &__head {
        margin-bottom: 40px;
    }
    &__body {
        align-items: flex-start;
    }
    &__info {
        width: 60.5%;
        padding-right: 50px;
    }
    &__img {
        width: 39.5%;
    }
}

@media (max-width: 992px) {
    .about {
        &__body {
            flex-direction: column-reverse;
        }
        &__info,
        &__img {
            width: 100%;
        }
        &__info {
            padding-right: 0;
        }
        &__img {
            margin-bottom: 30px;
            img {
                margin: 0 auto;
            }
        }
    }
}

@media (max-width: 767px) {
    .about {
        margin-bottom: 40px;
    }
}