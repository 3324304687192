.advantages {
    &__head {
        margin-bottom: 52px;
    }
    &__item {
        width: 50%;
        margin-bottom: 60px;
    }
}

@media (max-width: 992px) {
    .advantages {
        &__item {
            width: 100%;
        }
    }
}

@media (max-width: 575px) {
    .advantages {
        &__head,
        &__item {
            margin-bottom: 30px;
        }
    }
}