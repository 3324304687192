.types {
    margin-bottom: 106px;
    &__head {
        margin-bottom: 54px;
    }
    &__list {
        margin-bottom: 30px;
        &-item {
            width: 25%;
            margin-bottom: 24px;
        }
    }
    &__controls {
        justify-content: center;
    }
    &__link {
        max-width: 188px;
    }
}

@media (max-width: 992px) {
    .types {
        margin-bottom: 60px;
        &__list {
            &-item {
                width: 50%;
            }
        }
    }
}

@media (max-width: 575px) {
    .types {
        margin-bottom: 30px;
    }
}

@media (max-width: 420px) {
    .types {
        &__list {
            &-item {
                width: 100%;
            }
        }
    }
}