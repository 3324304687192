.stand {
    margin-bottom: 92px;
    &__carousel {
        &.big {
            height: 414px;
        }
        &.small {
            display: none;
        }
    }
    &__img {
        position: relative;
        width: 100%;
        background-position: center;
        background-repeat: no-repeat;
        transition: height .3s ease-out;
    }
    .slick {
        &-slide {
            margin: 0 9px;
            transition: width .3s ease-out;
            outline: none;
        }
        &-dots {
            display: flex;
            justify-content: center;
            align-items: center;
            li {
                width: 10px;
                height: 10px;
                margin-right: 4px;
                margin-left: 4px;
                background-color: transparent;
                border: 1px solid rgb(var(--charCoal));
                border-radius: 50%;
                button {
                    width: 10px;
                    height: 10px;
                }
                &.slick-active {
                    background-color: rgb(var(--charCoal));
                }
            }
        }
        &-dotted.slick-slider {
            margin-bottom: 36px;
        }
    }
}

@media (max-width: 992px) {
    .stand {
        margin-bottom: 60px;
        &__carousel {
            &.big {
                display: none;
            }
            &.small {
                display: block;
            }
        }
        &__img {
            img {
                width: 100%;
            }
        }
        .slick {
            &-slide {
                margin: 0;
            }
        }
    }
}